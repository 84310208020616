import React, { useState } from 'react';
import Logo from '../../assets/Logo.jpg';
import { FaRegQuestionCircle, FaMapMarkedAlt, FaGem, FaWallet, FaQuestion } from 'react-icons/fa';
import { TbActivityHeartbeat } from "react-icons/tb";
import { BsTwitterX } from "react-icons/bs";
import { FaDiscord } from "react-icons/fa";

import './NavBar.css';
import ConnectWalletButton from '../ConnectWalletButton/ConnectWalletButton';

const Navbar = () => {
  
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-gradient270 lg:bg-gradient text-white w-full fixed top-0 z-50">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center">
            <a href="#" className="flex items-center py-5 text-gray-200 hover:text-gray-400">
              <span className="font-bold hidden mr-2 lg:block customicons">
                <img className='w-14' src={Logo} alt="Logo" />
              </span>
              <TbActivityHeartbeat className='w-8 mr-2 lg:hidden'></TbActivityHeartbeat>
            </a>
            <h1 className='font-bold font-Kode tracking-wider'>PULSECHAIN COMICS</h1>
          </div>
          
          <div className="hidden md:flex items-center space-x-1 ml-auto font-Kode">
            <a href="#" className="py-5 pl-3 pr-2 hover:underline customicons">About</a>
            <span>-</span>
            <a href="#" className="py-5 pl-3 pr-2 hover:underline customicons">Road Map </a>
            <span> -</span>
            <a href="#" className="py-5 pl-3 pr-2 hover:underline customicons">FAQs</a>
            <span> -</span>
            <a href="#" className="py-5 pl-3 pr-2 hover:underline customicons">Mint</a>
            {/* <span className='pr-2'>-</span>
            <ConnectWalletButton></ConnectWalletButton> */}

            {/* Íconos de Twitter e Instagram agregados aquí */}
            <span className='pl-2'>-</span>
            <a href="https://twitter.com/pls_comics" target="_blank" className="py-5 pl-3 customicons ">
              <BsTwitterX className="text-lg mr-2 " />
            </a>
            <span className=''>-</span>
              <a href="https://discord.gg/cH8e6ggg" target="_blank"className="py-5 pl-3 pr-2 customicons">
              <FaDiscord className="text-xl mr-2 " />
            </a>
          </div>
          
          <div className="md:hidden">
            <button onClick={() => setIsOpen(!isOpen)} className="mobile-menu-button">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path></svg>
            </button>
          </div>
        </div>
      </div>

      <div className={`md:hidden ${isOpen ? 'translate-x-0' : '-translate-x-full'} transform top-16 left-0 w-full absolute transition-transform duration-300 ease-in-out`}>
        <div className="bg-gradient270 px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <a href="#" className="block px-3 py-2 rounded-md text-base font-medium text-gray-200 flex items-center space-x-2 font-Kode">
            <FaRegQuestionCircle className="text-lg mr-2" /> <span>About</span>
          </a>
          <a href="#" className="block px-3 py-2 rounded-md text-base font-medium text-gray-200 flex items-center space-x-2 font-Kode">
            <FaMapMarkedAlt className="text-lg mr-2" /> <span>Road Map</span>
          </a>
          <a href="#" className="block px-3 py-2 rounded-md text-base font-medium text-gray-200 flex items-center space-x-2 font-Kode">
            <FaQuestion className="text-lg mr-2" /> <span>FAQs</span>
          </a>
          <a href="#" className="block px-3 py-2 rounded-md text-base font-medium text-gray-200 flex items-center space-x-2 font-Kode">
            <FaGem className="text-lg mr-2" /> <span>Mint</span>
          </a>
          {/* <a href="#" className="block px-3 py-2 rounded-md text-base font-medium text-gray-200 flex items-center space-x-2 font-Kode">
            <FaWallet className="text-lg mr-2" /> <span>Connect Wallet</span>
          </a> */}
          <a href="https://twitter.com/pls_comics" target="_blank" className="block px-3 py-2 rounded-md text-base font-medium text-gray-200 flex items-center space-x-2 font-Kode">
            <BsTwitterX className="text-lg mr-2" /><span>Twitter</span>
          </a>
          <a href="https://discord.gg/cH8e6ggg" target="_blank" className="block px-3 py-2 rounded-md text-base font-medium text-gray-200 flex items-center space-x-2 font-Kode">
            <FaDiscord className="text-lg mr-2" /><span>Discord</span>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
