import React from 'react'
import StarsCanvas from './canvas/Stars'
import { useState } from 'react';



const Stars = () => {
  const [activeComponent, setActiveComponent] = useState(0); // Track the active component index


  const handleLeftClick = () => {
    setActiveComponent((prev) => (prev === 0 ? 1 : 0)); // Toggle between active components (0 and 1)

    
  };
  

  const handleRightClick = () => {
    setActiveComponent((prev) => (prev === 0 ? 1 : 0)); // Toggle between active components (0 and 1)
  };

  const [visible,setVisible] = useState(false);

  const handleClick = () => {
    setVisible(!visible);
  };

  const animationVariants = {
    initial: { opacity: 0, x: '100%' },
    animate: { opacity: 1, x: '0%' },
    exit: { opacity: 0, x: '-100%' },
    transition: { duration: 0.5 },
  };





  return (
      <StarsCanvas />

  )
}

export default Stars;