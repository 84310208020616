import React from 'react';
import './Home.css';
import Stars from '../components/Stars';
import Navbar from '../components/NavBar/NavBar';
import Footer from '../components/Footer/Footer';
import About from '../components/about/About';
import ImageSection from '../components/imagesection/ImageSection';
const Home = () => {
    return (
        <> 
         <div className='bg-black'>
                <Stars />
                <Navbar />
                <div className="flex justify-center items-center mt-14 lg:mt-0  h-[70vh] lg:h-[100vh] bg-black flex-col">
                    <div className="relative">
                    <div className="absolute -inset-1 bg-gradient animate-pulse rounded-lg blur"></div>
                        <div className="p-12 bg-black rounded-lg relative shadow-lg">
                            <h1 className="text-[10px] font-bold text-white underline text-[45px] tracking-widest font-Kode">BUILDING</h1>
                            <div className="flex justify-center items-center mt-4">
                                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
                            </div>
                        </div>
                    </div>
                    <svg version="1.1" id="Layer_1" className="w-full sm:md:w-auto" viewBox={window.innerWidth < 640 ? "387 50 225 150" : "0 25 1000 150"}>
                        <defs>
                        <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" style={{stopColor: '#FD0212', stopOpacity: 1}} /> 
                            <stop offset="27%" style={{stopColor: '#FD0212', stopOpacity: 1}} /> 
                            <stop offset="29%" style={{stopColor: '#800080', stopOpacity: 1}} /> 
                            {/* <stop offset="31%" style={{stopColor: '#F77FBE', stopOpacity: 1}} />  */}
                            <stop offset="33%" style={{stopColor: '#800080', stopOpacity: 1}} /> 
                            <stop offset="34%" style={{stopColor: '#0000FF', stopOpacity: 1}} /> 
                            <stop offset="35.5%" style={{stopColor: '#5E6CF2', stopOpacity: 1}} /> 
                            <stop offset="41.5%" style={{stopColor: '#02CCFE', stopOpacity: 1}} /> 
                            <stop offset="80%" style={{stopColor: '#02CCFE', stopOpacity: 1}} /> 
                            <stop offset="100%" style={{stopColor: '#02CCFE', stopOpacity: 1}} /> 
                        </linearGradient>


                        </defs>
                        <path id='path1' d="M 0 150 h 482 c 0.2 0 0.4 -0.1 0.5 -0.3 l 1.8 -5.3 c 0.2 -0.5 0.9 -0.4 1 0.1 l 1.1 5.9 c 0.1 0.5 0.9 0.5 1 0 l 6.4 -37 c 0.1 -0.6 0.9 -0.5 1 0 l 6.7 55 c 0.1 0.6 0.9 0.6 1 0 l 3.9 -20 c 0.1 -0.5 0.9 -0.5 1 0 l 0.5 2.3 c 0.1 0.5 0.9 0.5 1 0 l 2.5 -12.7 c 0.1 -0.5 0.9 -0.5 1 0 l 1.9 9.3 c 0 0.2 0.1 0.2 0.1 0 l 0.3 -1.4 c 0 -0.1 0.1 -0.1 0.2 0 l 1.3 3.9 c 0.1 0.2 0.3 0.3 0.5 0.3 H 1500" stroke="url(#gradient1)"/>
                        <path id='path2' d="M 0 150 h 482 c 0.2 0 0.4 -0.1 0.5 -0.3 l 1.8 -5.3 c 0.2 -0.5 0.9 -0.4 1 0.1 l 1.1 5.9 c 0.1 0.5 0.9 0.5 1 0 l 6.4 -37 c 0.1 -0.6 0.9 -0.5 1 0 l 6.7 55 c 0.1 0.6 0.9 0.6 1 0 l 3.9 -20 c 0.1 -0.5 0.9 -0.5 1 0 l 0.5 2.3 c 0.1 0.5 0.9 0.5 1 0 l 2.5 -12.7 c 0.1 -0.5 0.9 -0.5 1 0 l 1.9 9.3 c 0 0.2 0.1 0.2 0.1 0 l 0.3 -1.4 c 0 -0.1 0.1 -0.1 0.2 0 l 1.3 3.9 c 0.1 0.2 0.3 0.3 0.5 0.3 H 1500" stroke="url(#gradient1)"/>
                    </svg>

                </div>


                <Stars />
                {/* <ImageSection/>
                <About /> */}
                <Footer />
            </div>
        </>
    );
};

export default Home;
//VIEWPORT TELEFONO PA                <svg version="1.1" id="Layer_1" className="w-full sm:md:w-auto" viewBox={window.innerWidth < 640 ? "387 50 225 150" : "0 25 1000 150"}>
{/* <stop offset="0%" style={{stopColor: '#00eaff', stopOpacity: 1}} /> 
                        <stop offset="25%" style={{stopColor: '#0080ff', stopOpacity: 1}} /> 
                        <stop offset="50%" style={{stopColor: '#8000ff', stopOpacity: 1}} /> 
                         <stop offset="75%" style={{stopColor: '#e619e6', stopOpacity: 1}} /> 
                        <stop offset="100%" style={{stopColor: '#ff0000', stopOpacity: 1}} /> 
                         */}