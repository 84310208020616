import React from 'react';
import { FaDiscord } from 'react-icons/fa';
import { BsTwitterX } from "react-icons/bs";

const Footer = () => {
  return (
    <footer className="bg-black text-white pt-10 pb-5 font-Kode">
      <div className="max-w-6xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">

          <div>
            <h2 className="text-xl mb-2 underline">Project</h2>
            <ul>
              <li className='customicons'>Blockchain: PulseChain</li>
              <li className='customicons'>Total Mints: 0</li>
              <li className='customicons'>Mint Date: TBA</li>
              <li className='customicons'>FAQs</li>
              <li className='customicons'>Roadmap</li>
            </ul>
          </div>

          <div>
            <h2 className="text-xl mb-2 underline">Company</h2>
            <ul>
              <li className='customicons' >About</li>
              <li className='customicons'> Our Team</li>
            </ul>
          </div>
          
          <div>
            <h2 className="text-xl mb-2 underline">Contact</h2>
            <ul>
              <li>pulsechain.comics@gmail.com</li>
              <div className="flex space-x-4 mt-4">
                <a href="https://twitter.com/pls_comics" target="_blank" rel="noopener noreferrer" className='customicons'>
                  <BsTwitterX size="24" />
                </a>
                <a href="https://discord.gg/cH8e6ggg" target="_blank" rel="noopener noreferrer" className='customicons'>
                  <FaDiscord size="24" />
                </a>
              </div>
            </ul>
          </div>
        </div>
        <div className="text-center pt-8 border-t border-white mt-8">
          <p>&copy; 2024 PulseChain Comics. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
